<template>
  <div class="common-wrapper" id="commonwrapper">
    <aside class="aside">
      <div class="aside-header">
        <div class="deliver">
          <a-tooltip>
            <template #title>使用</template>
            <delivered-procedure-outlined @click="handleDeliver" v-if="userStore.isTenantAdmin" />
          </a-tooltip>
        </div>
        <auth-image :src="data.imageUrl" size="100px" />
        <div class="name">
          <a-typography-text v-if="data.name" :ellipsis="{ tooltip: data.name }" :content="data.name"></a-typography-text>
        </div>
        <div class="id">{{ data.bizId }}</div>
        <div class="desc">
          <a-typography-paragraph v-if="data.description" :ellipsis="{ rows: 3, tooltip: data.description }"
            :content="data.description" />
        </div>
      </div>
      <div class="aside-content" v-if="data.id">
        <!-- route.params.f==1 来源于产品库 isPlatformAdmin：用户为平台管理员  isRepo：是否为产品库产品 -->
        <div class="status" v-if="(route.params.f && userStore.isPlatformAdmin) || !data.isRepo">
          <div :class="['switch-btn', { 'switch-btn-active': data.status === 0 }]" style="cursor: pointer"
            >
            <check-outlined v-if="data.status === 0" />
            <pause-circle-outlined v-else />
            <span style="margin-left: 15px">{{ data.status === 0 ? '启用' : '停用' }}</span>
            <span class="tip" @click="changeStatus(data.status == 1 ? 0 : 1)"><SwapOutlined /> 切换到{{ data.status === 1 ? '启用' : '停用' }}</span>
          </div> 
        </div>
        <div class="status" v-else>
          <div :class="['switch-btn', { 'switch-btn-active': data.status === 0 }]" style="width: 100%">
            <check-outlined v-if="data.status === 0" />
            <pause-circle-outlined v-else />
            <span style="margin-left: 15px">{{ data.statusLabel }}</span>
          </div>
        </div>

        <div class="col1">
          <div class="attr-key">实体设备</div>
          <div class="attr-value">{{ data.isReal ? "是" : "否" }}</div>
        </div>
        <div class="col1">
          <div class="attr-key">长联设备</div>
          <div class="attr-value">{{ data.isLongOnline ? "是" : "否" }}</div>
        </div>
        <div class="col1">
          <div class="attr-key">通讯方式</div>
          <div class="attr-value">{{ data.communicationTypeLabel }}</div>
        </div>
        <div class="col1">
          <div class="attr-key">通讯超时时间</div>
          <div class="attr-value">{{ data.timeout }} 秒</div>
        </div>
        <div class="col1">
          <div class="attr-key">产品型号</div>
          <div class="attr-value">{{ data.model }}</div>
        </div>
        <div class="col1"></div>
        <div class="col2">
          <div class="attr-key">产品厂商</div>
          <div class="attr-value">{{ data.factory }}</div>
        </div>
        <div class="col2">
          <div class="attr-key">产品简介</div>
          <div class="attr-value">{{ data.description }}</div>
        </div>
      </div>
    </aside>
    <main class="content" v-if="showTab">
      <a-tabs v-model:activeKey="activeKey">
        <a-tab-pane key="1" tab="功能模型">
          <functionTabs :productStatus="data.status" />
        </a-tab-pane>
        <a-tab-pane v-if="!route.params.f" key="2" tab="设备列表">
          <ProductDetailDevice v-if="activeKey === '2'" :productId="route.params.id" :productName="data.name">
          </ProductDetailDevice>
        </a-tab-pane>
        <a-tab-pane key="3" tab="告警码">
          <alarmCode v-if="activeKey === '3'" :productId="route.params.id" />
        </a-tab-pane>
      </a-tabs>
    </main>
  </div>
</template>

<script setup>
import { onMounted, ref, watch, nextTick } from "vue";
import { useRoute } from "vue-router";
import { DeliveredProcedureOutlined,SwapOutlined } from "@ant-design/icons-vue";
import { apiProductLibrary } from "@/api/IoT/productCenter.js";
import { Modal, message } from "ant-design-vue";
import ProductDetailDevice from "./components/ProductDetailDevice.vue";
import alarmCode from "./components/alarmCode.vue";

import functionTabs from "./components/functionTabs.vue";
import { apiProductDetail } from "@/api/IoT/productCenter.js";
import authImage from "@/components/basic/authImage/index.vue";
import { useUserStore } from "@/store/modules/user";
const userStore = useUserStore();

const route = useRoute();
const data = ref({});
const activeKey = ref('1')
const showTab = ref(true)

onMounted(() => {
  initBaseInfo();
});

watch(() => route.fullPath, () => {
  if (route.fullPath.includes('/IoTManage/productCenter/productDetail')) {
    showTab.value = false
    nextTick(() => {
      showTab.value = true
      initBaseInfo();
    })
  } else {
    showTab.value = false
  }
})

const initBaseInfo = () => {
  if (route.params.f) {
    apiProductDetail.detail(route.params.id).then(({ result }) => {
      data.value = result;
      data.value.isRepo = true
    });
  } else {
    apiProductDetail.detail2(route.params.id).then(({ result }) => {
      data.value = result;
    });
  }

};

const handleDeliver = () => {
  Modal.confirm({
    title: "提醒",
    content: "请确认是否引用该产品？",
    onOk() {
      return new Promise((resolve) => {
        apiProductLibrary
          .deliver(route.params.id)
          .then(() => {
            Modal.success({
              content:
                "该产品已经从产品库导入到企业中的产品管理列表，请前往产品管理功能查看！",
              okText: "关闭",
            });
          })
          .finally(() => {
            resolve();
          });
      });
    },
  });
};
const changeStatus = (status) => {
  console.log(status , data.value.status);
  if (status !== data.value.status) {
    Modal.confirm({
      title: "提醒",
      content: "请确认是否切换产品状态？",
      onOk() {
        return new Promise((resolve) => {
          let m = {
            id: route.params.id,
            status: status,
          };
          apiProductLibrary
            .changeStatus(m)
            .then(() => {
              initBaseInfo();
              message.success("切换成功");
            })
            .finally(() => {
              resolve();
            });
        });
      },
    });
  }

};

</script>
<style lang="less" scoped>
.common-wrapper {
  position: relative;
  background: none;
  padding: 0;
  height: 100%;
}

.aside {
  position: absolute;
  left: 0;
  top: 0;
  flex-direction: column;
  width: 240px;
  height: 100%;
  background-color: #fff;
  font-size: 14px;
  overflow: auto;

  &-header {
    padding: 18px 20px 20px;

    .deliver {
      color: #0256ff;
      font-size: 12px;
      text-align: right;
      height: 14px;
    }

    .name {
      padding-top: 20px;
      font-weight: bold;
      color: #38404c;
    }

    .id {
      color: #999;
      line-height: 38px;
    }

    .desc {
      font-size: 14px;
      line-height: 16px;
    }

    :deep .ant-typography {
      margin: 0;
      color: #666;
    }
  }

  &-content {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #e5e6e8;

    .status {
      display: flex;
      align-items: center;
      height: 40px;
      color: #fff;
      width: 100%;

      .switch-btn {
        position: relative;
        display: inline-block;
        height: 100%;
        width: 100%;
        line-height: 40px;
        background-color: #d9d9d9;
        text-align: center;
        color: #fff;

        transition: width 0.5s;

        .tip {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          display: none;
          background-color: #799aff;
        }
      }

      .switch-btn:hover {

        .tip {
          display: block
        }
      }

      .switch-btn-active {
        background-color: #0256FF;
      }
    }

    .col1 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 60px;
      border-top: 1px solid #e5e6e8;
      text-align: center;
      width: 50%;
    }

    .attr-key {
      color: #999;
      font-size: 12px;
      line-height: 20px;
    }

    .attr-value {
      color: #38404c;
      font-size: 14px;
      line-height: 24px;
    }

    .col2 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 60px;
      border-top: 1px solid #e5e6e8;
      text-align: center;
      width: 100%;
    }

    .col1:nth-child(2n) {
      border-right: 1px solid #e5e6e8;
    }
  }
}

.content {
  position: relative;
  margin-left: 248px;
  padding: 0 20px;
  height: 100%;
  background-color: #fff;
}
</style>
