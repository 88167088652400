<template>
    <div class="function-tabs">
        <a-tabs v-model:activeKey="activeTab" size="small" type="card">
            <a-tab-pane v-for="(tab) in tabs" :key="tab.value" :tab="tab.label">
                <function-template :tab="tab" :productStatus="productStatus" />
            </a-tab-pane>
        </a-tabs>
    </div>
</template>
<script setup>
import { ref, onMounted, provide, defineProps } from 'vue'
import { apiProductDetailProductParams, apiProductDetailDeviceParams, apiProductDetailDeviceAttribute, apiProductDetailDeviceEvent, apiProductDetailDeviceService } from "@/api/IoT/productCenter.js";

import { apiDict } from '@/api/dict';
import functionTemplate from './functionTemplate.vue';

defineProps({
    productStatus: Number
})

const activeTab = ref('01')
const functionType = [{ label: '自定义功能', value: '04' }]
const dataType = ref([])
const unit = ref([])
const rwType = ref([])
const tabs = ref([])
const tabsFunc = [apiProductDetailProductParams, apiProductDetailDeviceParams, apiProductDetailDeviceAttribute, apiProductDetailDeviceEvent, apiProductDetailDeviceService]

provide('provideParams', { functionType, dataType, unit, rwType })

onMounted(() => {
    apiDict.selective('PARAM_DATA_TYPE').then(({ result }) => {
        dataType.value = result
    })
    apiDict.selective('UNIT').then(({ result }) => {
        unit.value = result
    })
    apiDict.selective('RW_TYPE').then(({ result }) => {
        rwType.value = result
    })
    apiDict.selective('PRODUCT_FUNCTION_CATEGORY').then(({ result }) => {
        tabs.value = result.map((e, idx) => {
            return {
                ...e,
                func: tabsFunc[idx],
            }
        })
    })
})
</script>
<style lang='less' scoped>
:deep .ant-tabs {
    height: calc(100vh - 180px);

    .ant-tabs-nav::before {
        display: none;
    }

    .ant-tabs-tab {
        margin: 0 !important;
        border-radius: 0 !important;
        border-left: none !important;

        &:first-child {
            border-left: 1px solid #f0f0f0 !important;
        }

        &-active {
            background-color: @primary-color !important;

            .ant-tabs-tab-btn {
                color: #fff !important;
            }
        }

    }

    &-content {
        height: 100%;
    }
}

:deep .function-template {
    position: relative;
    height: 100%;

    .table-header {
        display: flex;
        justify-content: space-between;
        position: absolute;
        top: -52px;
        right: 0;
        width: calc(100vw - 950px);

        .category-search {
            width: 248px;
        }

        .search-container {

            .container_wrap {
                width: 212px;
            }
        }
    }

    .template-table {
        padding-top: 16px;
    }
}
</style>