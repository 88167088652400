<template>
    <a-modal :visible="visible" title="设定参数值" @ok="ok" @cancel="emit('cancel')" :confirm-loading="loading">
        <a-form ref="formRef" :model="formState" :rules="rulesRef" :label-col="{ span: 4 }">
            <a-form-item name="identifier" label="功能标识">
                <a-input v-model:value="formState.identifier" placeholder="请输入" disabled></a-input>
            </a-form-item>
            <a-form-item name="functionName" label="功能名称">
                <a-input v-model:value="formState.functionName" placeholder="请输入" disabled></a-input>
            </a-form-item>
            <a-form-item name="value" label="参数值">
                <a-input v-if="data.dataType == '01'" v-model:value="formState.value" :maxlength="valueLimit"></a-input>
                <a-input-number v-else-if="data.dataType == '02'" style="width: 100%;" v-model:value="formState.value"
                    :min="valueLimit.min" :max="valueLimit.max" :addon-after="valueLimit.unit" />
                <a-input-number v-else-if="data.dataType == '03'" style="width: 100%;" v-model:value="formState.value"
                    :min="valueLimit.min" :max="valueLimit.max" :addon-after="valueLimit.unit" />
                <a-select v-else v-model:value="formState.value">
                    <a-select-option v-for="item in valueLimit" :key="item.value"
                        :value="item.value">{{ item.key }}</a-select-option>
                </a-select>
            </a-form-item>
        </a-form>
    </a-modal>
</template>

<script setup>
import { defineProps, reactive, ref, watch, defineEmits, computed } from 'vue';

const emit = defineEmits(['ok', 'cancel'])

const props = defineProps({
    data: Object,
    visible: Boolean
})

const valueLimit = computed(() => {
    let { dataType, valueDescription, valueDescriptionContent } = props.data
    if (dataType === '01') {
        return Number(valueDescriptionContent)
    } else if (dataType === '02' || dataType === '03') {
        let min = valueDescription.find(e => e.key === 'MIN')
        let max = valueDescription.find(e => e.key === 'MAX')
        let unit = valueDescription.find(e => e.key === 'UNIT')
        return {
            min: Number(min.value),
            max: Number(max.value),
            unit: unit.value,
        }
    }
    return valueDescription
})

watch(() => props.data, (data) => {
    formState.identifier = data.identifier
    formState.functionName = data.functionName
    formState.value = data.value
})
const formState = reactive({
    identifier: undefined,
    functionName: undefined,
    value: undefined,
})
const formRef = ref()
const rulesRef = reactive({
    value: [{ required: true, message: '请输入参数值' }],
})

const ok = () => {
    formRef.value.validateFields().then(() => {
        let m = {
            id: props.data.id,
            value: formState.value
        }
        emit('ok', m)
    });

}


</script>
<style lang='less' scoped></style> 