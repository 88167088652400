<template>
  <div class="product-device-search">
    <CategorySearch :source="filterSource" @change="search" />
    <div class="product-device-button">
      <a-dropdown>
        <template #overlay>
          <a-menu>
            <a-menu-item key="1">
              <DownloadButton label="下载导入模板" :customFunc="customDownload"></DownloadButton>
            </a-menu-item>
            <a-menu-item key="2">
              <UploadButton label="上传导入文件" fileType="file" :customFunc="customUpload" @uploadSuccess="uploadSuccess">
              </UploadButton>
            </a-menu-item>
          </a-menu>
        </template>
        <a-button type="primary">批量导入</a-button>
      </a-dropdown>
      <a-button type="primary" @click="showModal(null)">手动添加</a-button>
    </div>
  </div>
  <BasicTable ref="tableRef" v-bind="tableConfig" @page-change="pageChange">
  </BasicTable>
  <alarmCodeEditModal v-if="modalVisible" v-model:visible="modalVisible" :productId="productId" :productName="productName"
    :detail="currentRow" @success="editSuccess"></alarmCodeEditModal>
</template>
  
<script setup>
import { defineProps, onMounted, ref, reactive } from "vue";
import CategorySearch from "@/components/business/CategorySearch/categorySearch.vue";
import { apiAlarmCode } from "@/api/IoT/productManage.js";
import { Modal, message } from "ant-design-vue";
import { BasicTable } from "@/components/basic/table";
import alarmCodeEditModal from "./alarmCodeEditModal";
import { apiDictListByCode } from "@/api/common";
import _ from 'lodash';
const props = defineProps({
  productId: {
    type: String,
    default: "",
  },
  productName: {
    type: String,
    default: "",
  },
});

const filterSource = ref([
  {
    label: "告警码",
    type: "input",
    bindKey: "alarmCode",
    placeholder: "请输入",
  },
  {
    label: "触发等级",
    type: "select",
    bindKey: "alarmTriggerLevel",
    placeholder: "请选择",
    options:[]
  },
]);
let searchData = {};
const search = (data) => {
  searchData = data;
  tableRef.value.paginationProps.current = 1;
  initTableData();
};

const customDownload = async () => {
  return await apiAlarmCode.download();
};
const customUpload = async (data) => {
  return await apiAlarmCode.upload(data, props.productId);
};
const uploadSuccess = () => {
  tableRef.value.paginationProps.current = 1;
  initTableData();
};

const tableRef = ref();
const tableConfig = reactive({
  showIndex: false,
  showSelect: false,
  pagination: true,
  isPageBottom: true,
  remote: false,
  loading: false,
  scroll: {
    scrollToFirstRowOnChange: true,
    y: "calc(100vh - 320px)",
  },
  columns: [
    {
      title: "告警码",
      type: "text",
      key: "alarmCode",
    },
    {
      title: "告警类型",
      type: "text",
      key: "alarmTypeName",
    },
    {
      title: "描述",
      type: "text",
      key: "alarmDesc",
    },
    {
      title: "触发等级",
      type: "text",
      key: "alarmTriggerLevelName",
    },
    {
      title: "复归等级",
      type: "text",
      key: "alarmRelapseLevelName",
    },
    {
      title: "触发确认",
      type: "text",
      key: "alarmConfirmTypeName",
    },
  ],
  action: {
    title: "操作",
    width: 70,
    fixed: "right",
    items: [
      {
        type: "icon",
        icon: "FormOutlined",
        iconTip: "修改",
        onClick: (row) => {
          showModal(row);
        },
      },
      {
        type: "icon",
        icon: "DeleteOutlined",
        iconTip: "删除",
        onClick: (row) => {
          Modal.confirm({
            title: "提示",
            content: "请确认是否删除？",
            okType: "danger",
            onOk: () => {
              return new Promise((resolve, reject) => {
                apiAlarmCode
                  .delete(row.id)
                  .then(async () => {
                    message.success("删除成功");
                    initTableData();
                    resolve();
                  })
                  .catch(() => {
                    reject();
                  });
              });
            },
            onCancel() {
              console.log("Cancel");
            },
          });
        },
      },
    ],
  },
});
const initTableData = async () => {
  const { current, pageSize } = tableRef.value.paginationProps;
  const params = {
    productId: props.productId,
    pageNo: current,
    pageSize,
    ...searchData,
  };
  tableConfig.loading = true;
  try {
    const res = await apiAlarmCode.list(params);
    const { records, total } = res?.result ?? {};
    tableConfig.loading = false;
    tableRef.value.dataSourceRef = records;
    tableRef.value.paginationProps.total = total;
  } catch (error) {
    tableConfig.loading = false;
    console.log(error);
  }
};
const pageChange = (pageIndex) => {
  tableRef.value.paginationProps.current = pageIndex;
  initTableData();
};

const initOptions = () => {
  apiDictListByCode("ALARM_LEVEL").then((res) => {
    const _list = _.cloneDeep(filterSource.value);
    _list[1].options = res.result;
    filterSource.value = _list;
  })
};

const modalVisible = ref(false);
const currentRow = ref(null);
const showModal = (row) => {
  currentRow.value = row;
  modalVisible.value = true;
};
const editSuccess = async () => {
  initTableData();
};

onMounted(() => {
  tableRef.value.paginationProps.pageSize = 20;
  initTableData();
  initOptions()

});
</script>
  
<style lang="less" scoped>
.product-device-search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 16px;

  ::v-deep .category-search {
    width: 600px;
    margin-bottom: 0;
  }

  ::v-deep .container_wrap {
    width: 560px !important;
    overflow-x: hidden !important;
  }
}

.product-device-button {
  display: flex;
  gap: 10px;
}
</style>
  