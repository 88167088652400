<template>
  <BasicModal :title="title" width="500px" :visible="visible" :confirmLoading="confirmLoading" @ok="ok" @cancel="cancel">
    <BasicForm ref="formRef" :labelCol="{ span: 5 }" :formItemsMap="formItemsMap" v-model:formData="formData"
      @change="changeForm"></BasicForm>
  </BasicModal>
</template>

<script setup>
import {
  defineProps,
  computed,
  ref,
  defineEmits,
  reactive,
  onMounted,
} from "vue";
import { message } from "ant-design-vue";
import { apiAlarmCode } from "@/api/IoT/productManage";
import { apiDictListByCode } from "@/api/common";

const props = defineProps({
  detail: {
    type: Object,
    default: null,
  },
  visible: {
    type: Boolean,
    default: false,
  },
  productId: {
    type: String,
    default: "",
  },
  productName: {
    type: String,
    default: "",
  },
});
const emit = defineEmits(["change", "update:visible", "success"]);
const formRef = ref(null);

const title = computed(() => `${props.detail ? "修改" : "添加"}产品告警码`);

onMounted(async () => {
  initOptions()
  initFormData();
});

const validateDuplicate = async (data, value) => {
  let m = {
    code: value,
    id: props.detail?.id,
    productId: props.productId
  }
  if (value) {
    let { result } = await apiAlarmCode.validationUniq(m)
    if (result) {
      return Promise.resolve();
    }
    return Promise.reject('该项值已重复');
  }
  return Promise.resolve();
}

const formItemsMap = reactive({
  alarmType: {
    label: "告警类型",
    prop: "alarmType",
    disabled: true,
    type: "select",
    options: [],
    required: true,
    requiredMessage: "请选择",
  },
  alarmCode: {
    label: "告警码",
    prop: "alarmCode",
    type: "input",
    required: true,
    requiredMessage: "请输入",
    rules: [
      { validator: validateDuplicate, trigger: "blur" },
    ],
  },
  alarmDesc: {
    label: "告警描述",
    prop: "alarmDesc",
    type: "textarea",
    required: true,
    requiredMessage: "请输入",
  },
  alarmTriggerLevel: {
    label: "触发等级",
    prop: "alarmTriggerLevel",
    type: "select",
    options: [],
    required: true,
    requiredMessage: "请选择",
  },
  alarmRelapseLevel: {
    label: "复归等级",
    prop: "alarmRelapseLevel",
    type: "select",
    options: [],
    required: true,
    requiredMessage: "请选择",
  },
  alarmConfirmType: {
    label: "确认方式",
    prop: "alarmConfirmType",
    type: "select",
    options: [],
    requiredMessage: "请选择",
  },
});

const initOptions = async () => {
  apiDictListByCode("ALARM_TYPE").then((res) => {
    formItemsMap.alarmType.options = res.result;
  })
  apiDictListByCode("ALARM_LEVEL").then((res) => {
    formItemsMap.alarmTriggerLevel.options = res.result;
    formItemsMap.alarmRelapseLevel.options = res.result;
  })
  apiDictListByCode("ALARM_CONFIRM_TYPE").then((res) => {
    formItemsMap.alarmConfirmType.options = res.result;
  })
};
const formData = reactive({
  alarmType: 'devAlarm',
  alarmCode: '',
  alarmDesc: "",
  alarmTriggerLevel: "",
  alarmRelapseLevel: "",
  alarmConfirmType: "",
});
const initFormData = async () => {
  if (!props.detail) return;
  for (const key in formData) {
    formData[key] = props.detail[key]
  }
};

const confirmLoading = ref(false);
const ok = async () => {
  try {
    await formRef.value.formRef.validateFields();
    const params = {
      productId: props.productId,
      ...formData,
    };
    if (props.detail) {
      // 修改
      params.id = props.detail.id;
    }
    confirmLoading.value = true;
    await apiAlarmCode[props.detail ? "edit" : "add"](params);
    message.success(`${props.detail ? "修改" : "添加"}成功`);
    emit("update:visible", false);
    emit("success");
    confirmLoading.value = false;
  } catch (errorInfo) {
    confirmLoading.value = false;
    console.log("Failed:", errorInfo);
  }
};
const cancel = () => {
  emit("update:visible", false);
};
</script>

<style lang="less" scoped></style>
