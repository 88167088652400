<template>
  <div class="product-device-search">
    <CategorySearch :source="filterSource" @change="search" />
    <div class="product-device-button">
      <a-dropdown>
        <template #overlay>
          <a-menu>
            <a-menu-item key="1">
              <DownloadButton
                label="下载导入模板"
                :customFunc="customDownload"
              ></DownloadButton>
            </a-menu-item>
            <a-menu-item key="2">
              <UploadButton
                label="上传导入文件"
                fileType="file"
                :customFunc="customUpload"
                @uploadSuccess="uploadSuccess"
              ></UploadButton>
            </a-menu-item>
          </a-menu>
        </template>
        <a-button type="primary">批量导入</a-button>
      </a-dropdown>
      <a-button type="primary" @click="showModal(null)">手动添加</a-button>
    </div>
  </div>
  <BasicTable ref="tableRef" v-bind="tableConfig" @page-change="pageChange">
    <template #slotName="{ row }">
        <router-link :to="{
                path: `/IoTManage/device/detail/${row.bizDeviceId}`,
                state: { tabName: row.name },
              }" >{{ row.bizDeviceId }}</router-link>
      </template>
  </BasicTable>
  <DeviceEditModal
    v-if="modalVisible"
    v-model:visible="modalVisible"
    :productId="productId"
    :productName="productName"
    :detail="currentRow"
    @success="editSuccess"
  ></DeviceEditModal>
</template>

<script setup>
import { defineProps, onMounted, ref, reactive } from "vue";
import CategorySearch from "@/components/business/CategorySearch/categorySearch.vue";
import { apiProjectDevice } from "@/api/IoT/productManage.js";
import { Modal, message } from "ant-design-vue";
import { BasicTable } from "@/components/basic/table";
import DeviceEditModal from "./DeviceEditModal";

const props = defineProps({
  productId: {
    type: String,
    default: "",
  },
  productName: {
    type: String,
    default: "",
  },
});

const filterSource = ref([
  {
    label: "设备名称",
    type: "input",
    bindKey: "name",
    placeholder: "请输入",
  },
  {
    label: "项目名称",
    type: "input",
    bindKey: "projectName",
    placeholder: "请输入",
  },
]);
let searchData = {};
const search = (data) => {
  searchData = data;
  tableRef.value.paginationProps.current = 1;
  initTableData();
};

const customDownload = async () => {
  return await apiProjectDevice.download(props.productId);
};
const customUpload = async (data) => {
  return await apiProjectDevice.upload(data, props.productId);
};
const uploadSuccess = () => {
  tableRef.value.paginationProps.current = 1;
  initTableData();
};

const tableRef = ref();
const tableConfig = reactive({
  showIndex: false,
  showSelect: false,
  pagination: true,
  isPageBottom: true,
  remote: false,
  loading: false,
  scroll: {
    scrollToFirstRowOnChange: true,
    y: "calc(100vh - 320px)",
  },
  columns: [
    {
      title: "设备ID",
      type: "customize",
      key: "bizDeviceId",
      slotName: "slotName",
      width: 150,
    },
    {
      title: "所属项目",
      type: "text",
      key: "projectName",
      width: 180,
    },
    {
      title: "设备名称",
      type: "text",
      key: "name",
      width: 180,
    },
    {
      title: "设备编码",
      type: "text",
      key: "code",
      width: 150,
    },
    {
      title: "外部设备ID",
      type: "text",
      key: "sourceDeviceId",
      width: 150,
    },
    {
      title: "设备位置",
      type: "text",
      key: "locationDesc",
      width: 180,
    },
  ],
  action: {
    title: "操作",
    width: 70,
    fixed: "right",
    items: [
      {
        type: "icon",
        icon: "FormOutlined",
        iconTip: "修改",
        onClick: (row) => {
          showModal(row);
        },
      },
      {
        type: "icon",
        icon: "DeleteOutlined",
        iconTip: "删除",
        onClick: (row) => {
          Modal.confirm({
            title: "提示",
            content: "是否删除该设备？",
            okType: "danger",
            onOk: () => {
              return new Promise((resolve, reject) => {
                apiProjectDevice
                  .delete(row.id)
                  .then(async () => {
                    message.success("删除成功");
                    initTableData();
                    resolve();
                  })
                  .catch(() => {
                    reject();
                  });
              });
            },
            onCancel() {
              console.log("Cancel");
            },
          });
        },
      },
    ],
  },
});
const initTableData = async () => {
  const { current, pageSize } = tableRef.value.paginationProps;
  const params = {
    productId: props.productId,
    pageNo: current,
    pageSize,
    ...searchData,
  };
  tableConfig.loading = true;
  try {
    const res = await apiProjectDevice.page(params);
    const { records, total } = res?.result ?? {};
    tableConfig.loading = false;
    tableRef.value.dataSourceRef = records;
    tableRef.value.paginationProps.total = total;
  } catch (error) {
    tableConfig.loading = false;
    console.log(error);
  }
};
const pageChange = (pageIndex) => {
  tableRef.value.paginationProps.current = pageIndex;
  initTableData();
};

const modalVisible = ref(false);
const currentRow = ref(null);
const showModal = (row) => {
  currentRow.value = row;
  modalVisible.value = true;
};
const editSuccess = async () => {
  initTableData();
};

onMounted(() => {
  tableRef.value.paginationProps.pageSize = 20;
  initTableData();
});
</script>

<style lang="less" scoped>
.product-device-search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 16px;
  ::v-deep .category-search {
    width: 600px;
    margin-bottom: 0;
  }
  ::v-deep .container_wrap {
    width: 560px !important;
    overflow-x: hidden !important;
  }
}
.product-device-button {
  display: flex;
  gap: 10px;
}
</style>
